import MainLayout from 'layout/MainLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';
import UserListPage from 'pages/main/users/UserListPage';
import PetListPage from 'pages/main/pets/PetListPage';
import HospitalListPage from 'pages/main/hospitals/HospitalListPage';
import ServiceListPage from 'pages/main/services/ServiceListPage';
import ClientListPage from 'pages/main/clients/ClientListPage';

// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: 'client',
      children: [
        {
          path: 'list',
          element: <ClientListPage />
        }
      ]
    },
    {
      path: 'users',
      children: [
        {
          path: 'list',
          element: <UserListPage />
        }
      ]
    },
    {
      path: 'pets',
      children: [
        {
          path: 'list',
          children: [
            {
              path: '',
              element: <PetListPage />
            },
            {
              path: ':userId',
              element: <PetListPage />
            }
          ]
        }
      ]
    },
    {
      path: 'hospitals',
      children: [
        {
          path: 'list',
          element: <HospitalListPage />
        },
      ]
    },
    {
      path: 'services',
      children: [
        {
          path: 'list',
          element: <ServiceListPage />
        },
      ]
    }
  ]
};

export default MainRoutes;
