// material-ui
import { Button, Divider, IconButton, useMediaQuery, useTheme } from '@mui/material';

// project-imports
import NavCard from './NavCard';
import Navigation from './Navigation';
import SimpleBar from 'components/third-party/SimpleBar';
import { useSelector } from 'store';
import { ThemeMode } from 'config';
import { Home, Setting2 } from 'iconsax-react';
import Profile from './Profile';
// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  const { drawerOpen } = useSelector((state) => state.menu);

  return (
    <div className={`flex flex-col h-screen ${theme.palette.mode === ThemeMode.DARK ? "bg-[#131920]" : "bg-[#ffffff]"}`}>
      <Divider className="my-5" />
      <div className="flex-grow ">
        {/* <Profile/> */}
        <Navigation />
        {/* {drawerOpen && !matchDownMD && <NavCard />} */}
      </div>
      {/* {drawerOpen ? (
        <Button variant="contained" startIcon={<Setting2  variant="Bold"/>} className="bg-[#7388A959] mx-6 my-2 py-3 text-[#606A82] font-semibold hover:bg-[#7c8da959] duration-200">Configuración</Button>
      ) : (
        <IconButton variant="contained" className="bg-[#7388A959] m-auto my-2 py-3 text-[#606A82] font-semibold hover:bg-[#7c8da959] duration-200">
          <Setting2  variant="Bold" />
        </IconButton>
      )} */}
    </div>
  );
};

export default DrawerContent;
