import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import { useLocation } from 'react-router';


const App = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  /*if (currentPath.includes('/lead')) {
    document.title = "IGS - Lead Manager";
  } else {
    document.title = "PET - Admin Panel";
  }*/

  document.title = "PET - Admin Panel";
  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <AuthProvider>
              <>
                <Notistack>
                  <Routes />
                  {/* <Customization /> */}
                  <Snackbar />
                </Notistack>
              </>
            </AuthProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
