// material-ui
import { Grid, Stack, Typography } from '@mui/material';

// project-imports
import AuthWrapper from 'sections/auth/AuthWrapper';
import Logo from 'components/logo';
import AuthCreatePassword from 'sections/auth/auth-forms/AuthCreatePassword';

// ================================|| RESET PASSWORD ||================================ //

const CreatePassword = () => (
  <AuthWrapper>
    <Grid container spacing={3}>
      <Grid item xs={12} sx={{ textAlign: 'center', marginBottom: 4 }}>
        <div className='flex flex-row justify-center'>
          <Logo />
          <p className='text-lg'>Voice Analyzer <span className='font-bold'>Platform</span></p>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Stack sx={{ mb: { xs: -0.5, sm: 0.5 } }} spacing={1}>
          <Typography variant="h3">Crear contraseña</Typography>
          <Typography color="secondary">Asigna una contraseña para crear tu cuenta</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <AuthCreatePassword />
      </Grid>
    </Grid>
  </AuthWrapper>
);

export default CreatePassword;
