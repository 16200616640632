import { useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    ClickAwayListener,
    Paper,
    Popper,
    Typography,
    useMediaQuery,
    Grid,
    Button,
} from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';
import { styled } from '@mui/material/styles';

// assets
import { Category } from 'iconsax-react';
import Avatar from 'components/@extended/Avatar';
import { ThemeMode } from 'config';
import useAuth from 'hooks/useAuth';
import useAuthStore from 'store/useAuthStore';
import { useNavigate } from 'react-router';

import vap from 'assets/images/home/vap.png';
import lead from 'assets/images/home/lead.png';
import labs from 'assets/images/home/labs.png';
import mam from 'assets/images/home/mam.png'
import tutorial from 'assets/images/home/tutorial.png'
import talent from 'assets/images/home/talent.png'

const actionSX = {
    mt: '6px',
    ml: 1,
    top: 'auto',
    right: 'auto',
    alignSelf: 'flex-start',

    transform: 'none'
};

const StyledButton = styled(Button)(({ theme }) => ({
    flexDirection: 'column',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    '& .MuiButton-startIcon': {
        margin: 0, // Eliminar márgenes si los hay
        marginBottom: theme.spacing(1) // Espacio entre el icono y el texto
    },
    '& .MuiSvgIcon-root': {
        fontSize: theme.spacing(6) // Tamaño del icono
    }
}));

const iconMapping = {
    VAP: vap,
    Lead: lead,
    Labs: labs,
    Talent: talent,
    MaM: mam,
    Tutorial: tutorial,
};

const refMapping = {
    VAP: "/vap",
    Lead: "/lead",
    Labs: "/",
    Talent: "/",
    MaM: "/",
    Tutorial: "/",
};

const AppsMenu = () => {
    const theme = useTheme();
    const { userApps } = useAuthStore();
    const navigate = useNavigate()
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const anchorRef = useRef(null);
    const [read] = useState(2);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleNavigate = (ref) => {
        navigate(ref)
        handleToggle()
    }

    const iconBackColorOpen = theme.palette.mode === ThemeMode.DARK ? 'secondary.200' : 'secondary.200';
    const iconBackColor = theme.palette.mode === ThemeMode.DARK ? 'background.default' : 'secondary.100';
    return (
        <Box sx={{ flexShrink: 0, ml: 0.5 }}>
            <IconButton
                color="secondary"
                variant="light"
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                size="large"
                sx={{ color: 'secondary.main', bgcolor: open ? iconBackColorOpen : iconBackColor, p: 1 }}
            >
                {/* <Badge badgeContent={read} color="success" sx={{ '& .MuiBadge-badge': { top: 2, right: 4 } }}> */}
                {/*<Windows variant="Bulk" />*/}
                <Category variant="Bulk" />
                {/* </Badge> */}
            </IconButton>
            <Popper
                placement="bottom"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [-180, 9]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions type="grow" position="top" in={open} {...TransitionProps}>
                        <Paper
                            sx={{
                                boxShadow: theme.customShadows.z1,
                                minWidth: 750,
                                maxWidth: 1024,
                                borderRadius: 1.5
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard elevation={0} border={false} content={false} className="p-4">
                                    <p className='font-semibold text-base mb-2'>Aplicaciones</p>
                                    <Grid container spacing={2}>
                                        {userApps?.length > 0 ? (
                                            <>
                                                {userApps.map((app) => {
                                                    const Icon = iconMapping[app.name];
                                                    return (
                                                        <Grid item xs={4} sm={4} md={4} key={app.id}>
                                                            <StyledButton variant="outlined" fullWidth onClick={(e) => {
                                                                handleNavigate(refMapping[app.name])
                                                                e.stopPropagation()
                                                            }
                                                            }>
                                                                <div className='flex flex-col'>
                                                                    <Avatar size="lg" className='p-2' src={Icon} alt={app.name}/>
                                                                    {/*<Avatar size="lg">{Icon ? <Icon /> : null}</Avatar>*/}
                                                                    <Typography variant="subtitle1">{app.name}</Typography>
                                                                </div>
                                                            </StyledButton>
                                                        </Grid>
                                                    );
                                                })}
                                            </>
                                        ) : (
                                            <div className='flex flex-col justify-center items-center'>
                                                <p className='text-base my-5 mx-4'>No tienes apps asignadas</p>
                                            </div>
                                        )}

                                    </Grid>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </Box>
    );
}

export default AppsMenu