import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    FormHelperText,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    Switch,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import useAuth from 'hooks/useAuth';

const AddUser = ({ customer, onCancel, updateData }) => {
    const theme = useTheme();
    const { updatePetClient, addUser } = useAuth();
    const isCreating = !customer;

    const roles = [
        {
            id: 'admin',
            name: 'Clinic'
        },
        {
            id: 'clinic',
            name: 'Admin'
        }
    ];

    const handleRolChange = (event) => {
        const rol = event.target.value;
        //setSelectedUserType(rol);
        formik.setFieldValue('rol', rol);
    };

    const [initialValuesState, setInitialValuesState] = useState({
        dni: customer?.dni || '',
        name: customer?.name || '',
        email: customer?.email || '',
        phone: customer?.phone || '',
        address: customer?.address || '',
    })

    useEffect(() => {
        if (customer) {
            setInitialValuesState({
                dni: customer?.dni || '',
                name: customer?.name || '',
                email: customer?.email || '',
                phone: customer?.phone || '',
                address: customer?.address || '',
            })
        } else {
            formik.resetForm()
            setInitialValuesState({
                dni: '',
                name: '',
                email: '',
                phone: '',
                address: '',
            })
        }
    }, [customer])

    const CustomerSchema = Yup.object().shape({
        //dni: Yup.string().max(30).required('Ingrese el dni del cliente'),
        name: Yup.string().max(255).required('Ingrese el nombre del cliente'),
        email: Yup.string().max(255).required('Ingrese un correo electrónico').email('Ingrese un correo electrónico válido'),
        phone: Yup.string().max(255).required('Ingrese el teléfono del cliente'),
        //address: Yup.string().max(255).required('Ingrese la dirección del cliente'),
    });

    const handleClose = () => {
        formik.resetForm();
        onCancel();
    };

    const formik = useFormik({
        initialValues: initialValuesState,
        enableReinitialize: true,
        //validationSchema: CustomerSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            try {
                if (customer) {
                    let formData = new FormData();
                    //formData.append("dni", values.dni);
                    formData.append("name", values.name);
                    formData.append("email", values.email);
                    formData.append("phone", values.phone);
                    //formData.append("address", values.address);
                    // for (var pair of formData.entries()) {
                    //    console.log(`${pair[0]}: ${pair[1]}`);
                    // }
                    updatePetClient(customer?.uuid, formData)
                        .then((response) => {
                            console.log(response.data)
                            if (response.data.success) {
                                dispatch(
                                    openSnackbar({
                                        open: true,
                                        message: 'Usuario editado exitosamente',
                                        variant: 'alert',
                                        alert: {
                                            color: 'success'
                                        },
                                        close: true
                                    })
                                );
                                updateData()
                                resetForm();
                            } else {
                                dispatch(
                                    openSnackbar({
                                        open: true,
                                        message: response.data.message,
                                        variant: 'alert',
                                        alert: {
                                            color: 'error'
                                        },
                                        close: true
                                    })
                                )
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            dispatch(
                                openSnackbar({
                                    open: true,
                                    message: error.message,
                                    variant: 'alert',
                                    alert: {
                                        color: 'error'
                                    },
                                    close: true
                                })
                            )
                        })
                        .finally(() => {
                            setSubmitting(false);
                            onCancel();
                        })
                } else {
                    let formData = new FormData();
                    formData.append("name", values.name);
                    formData.append("email", values.email);
                    formData.append("phone", values.phone);
                    formData.append("rol", values.rol);
                    addUser(formData)
                        .then((response) => {
                            console.log(response.data)
                            if (response.data.success) {
                                dispatch(
                                    openSnackbar({
                                        open: true,
                                        message: 'Usuario agregado exitosamente',
                                        variant: 'alert',
                                        alert: {
                                            color: 'success'
                                        },
                                        close: true
                                    })
                                );
                                updateData()
                                resetForm();
                            } else {
                                dispatch(
                                    openSnackbar({
                                        open: true,
                                        message: response.data.message,
                                        variant: 'alert',
                                        alert: {
                                            color: 'error'
                                        },
                                        close: true
                                    })
                                )
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            dispatch(
                                openSnackbar({
                                    open: true,
                                    message: error.message,
                                    variant: 'alert',
                                    alert: {
                                        color: 'error'
                                    },
                                    close: true
                                })
                            )
                        })
                        .finally(() => {
                            setSubmitting(false);
                            onCancel();
                        })
                }
                resetForm();
            } catch (error) {
                console.error(error);
            }
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

    return (
        <>
            <FormikProvider value={formik}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <DialogTitle>{customer ? 'Editar Usuario' : 'Agregar Usuario'}</DialogTitle>
                        <Divider />
                        <DialogContent >
                            <Grid container justifyContent={"center"}>
                                <Grid item xs={12} md={7}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Stack spacing={1.25}>
                                                <InputLabel htmlFor="name">Nombre</InputLabel>
                                                <TextField
                                                    fullWidth
                                                    id="name"
                                                    placeholder="Ingresa el nombre del usuario"
                                                    {...getFieldProps('name')}
                                                    error={Boolean(touched.name && errors.name)}
                                                    helperText={touched.name && errors.name}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack spacing={1.25}>
                                                <InputLabel htmlFor="customer-email">Correo electrónico</InputLabel>
                                                <TextField
                                                    fullWidth
                                                    id="customer-email"
                                                    placeholder="Ingresa el correo electrónico del usuario"
                                                    {...getFieldProps('email')}
                                                    error={Boolean(touched.email && errors.email)}
                                                    helperText={touched.email && errors.email}
                                                />
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Stack spacing={1.25}>
                                                <InputLabel htmlFor="name">Teléfono</InputLabel>
                                                <TextField
                                                    fullWidth
                                                    id="phone"
                                                    placeholder="Ingresa el teléfono del usuario"
                                                    {...getFieldProps('phone')}
                                                    error={Boolean(touched.phone && errors.phone)}
                                                    helperText={touched.phone && errors.phone}
                                                />
                                            </Stack>
                                        </Grid>
                                        {!customer &&
                                        <Grid item xs={12}>
                                        <Stack spacing={1.25}>
                                            <InputLabel htmlFor="customer-user-type">Rol</InputLabel>
                                            <FormControl fullWidth>
                                                <Select
                                                    id="user-type"
                                                    {...getFieldProps('rol')}
                                                    onChange={handleRolChange}
                                                    displayEmpty
                                                    input={<OutlinedInput id="select-column-hiding" placeholder="Sort by" />}
                                                    renderValue={(selected) => {
                                                        if (!selected) {
                                                            return <Typography variant="body1">Selecciona una opción</Typography>;
                                                        }

                                                        return <Typography variant="subtitle2">{selected.name}</Typography>;
                                                    }}
                                                >
                                                    {roles.map((rol) => (
                                                        <MenuItem key={rol?.id} value={rol}>
                                                            {rol.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            {/*!customer && errors.userType && (
                                                <FormHelperText error id="standard-weight-helper-text-email-login" sx={{ pl: 1.75 }}>
                                                    {errors.userType}
                                                </FormHelperText>
                                            )*/}
                                        </Stack>
                                    </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <Divider />
                        <DialogActions sx={{ p: 2.5 }}>
                            <Grid container justifyContent="end" alignItems="center">
                                <Grid item>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Button color="error" onClick={handleClose}>
                                            Cancelar
                                        </Button>
                                        <Button type="submit" variant="contained" className='bg-orange-500 hover:bg-orange-400' disabled={isSubmitting}>
                                            {customer ? 'Editar' : 'Agregar'}
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Form>
                </LocalizationProvider>
            </FormikProvider>
        </>
    );
};

AddUser.propTypes = {
    customer: PropTypes.any,
    onCancel: PropTypes.func
};

export default AddUser;