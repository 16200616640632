import PropTypes from 'prop-types';

// material-ui
import { Button, Dialog, DialogContent, Stack, Typography } from '@mui/material';

// project-imports
import Avatar from 'components/@extended/Avatar';
import { PopupTransition } from 'components/@extended/Transitions';

// assets
import { Trash, Warning2 } from 'iconsax-react';
import { LoadingButton } from '@mui/lab';

// ==============================|| CUSTOMER - DELETE ||============================== //

export default function AlertServiceDelete({ service, open, handleClose, handleSuspend, loading }) {
    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            keepMounted
            TransitionComponent={PopupTransition}
            maxWidth="xs"
            aria-labelledby="column-delete-title"
            aria-describedby="column-delete-description"
        >
            <DialogContent sx={{ mt: 2, my: 1 }}>
                <Stack alignItems="center" spacing={3.5}>
                    <Avatar color={service.status === 'active' ? "warning" : "info"} sx={{ width: 72, height: 72, fontSize: '2.75rem' }}>
                        <Warning2 variant="Bold" />
                    </Avatar>
                    <Stack spacing={2}>
                        <Typography variant="h4" align="center">
                            ¿Seguro que desea {service.status === 'active' ? "suspender" : "habilitar"} el servicio?
                        </Typography>
                        <Typography align="center">
                            <Typography variant="subtitle1" component="span">
                                {' '}
                                &quot;{service?.name || "Servicio"}&quot;{' '}
                            </Typography>
                        </Typography>
                    </Stack>

                    <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                        <Button fullWidth onClick={() => handleClose(false)} color="secondary" variant="outlined">
                            Cancelar
                        </Button>
                        <LoadingButton fullWidth color="info" loading={loading} className={` ${service.status === 'active' ? "bg-yellow-500 hover:bg-yellow-400" : "bg-cyan-500 hover:bg-cyan-400"} text-black font-medium`} variant="contained" onClick={() => handleSuspend(service)} autoFocus>
                            {service.status === 'active' ? "Suspender" : "Habilitar"}
                        </LoadingButton>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}
