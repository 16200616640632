// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Story, Fatrows, PresentionChart, User, Data, VolumeMute, Category, Personalcard, People, Profile2User, Pet, Hospital } from 'iconsax-react';
import useDataStore from 'store/useDataStore';
const state = useDataStore.getState();
// icons
const icons = {
  clients: Personalcard,
  user: Profile2User,
  sponsor: Data,
  campaign: VolumeMute,
  dashboard: Category,
  widgets: Story,
  statistics: Story,
  data: Fatrows,
  chart: PresentionChart,
  agent: Personalcard,
  egroup: People,
  pet: Pet,
  hospital: Hospital,
  service: Data
};

// ==============================|| MENU ITEMS - WIDGETS ||============================== //

const noAdmins = [{
  id: 'group-widget',
  title: "DASHBOARD",
  icon: icons.widgets,
  type: 'group',
  children: [
    {
      id: 'clients',
      title: <FormattedMessage id="clients" />,
      type: 'item',
      url: '/client/list',
      icon: icons.clients,
    },
    {
      id: 'users',
      title: <FormattedMessage id="users" />,
      type: 'item',
      url: '/users/list',
      icon: icons.user
    },
    {
      id: 'hospitals',
      title: <FormattedMessage id="hospitals" />,
      type: 'item',
      url: '/hospitals/list',
      icon: icons.hospital
    },
    {
      id: 'services',
      title: <FormattedMessage id="services" />,
      type: 'item',
      url: '/services/list',
      icon: icons.service
    },
  ]
}];

export default noAdmins;
