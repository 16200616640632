import { create } from 'zustand';

const useDataStore = create((set) => ({
  users: [],
  campaign: [],
  sponsor: [],
  agents: [],
  campaignSelected: { campaign: "", sponsor: "" },
  selectedData: null,
  boardUrl: null,

  // Método para actualizar el array de usuarios
  setUsers: (users) => set({ users }),

  // Método para añadir un usuario al array
  addUser: (user) => set((state) => ({ users: [...state.users, user] })),
  addCampaign: (campaign) => set((state) => ({ campaign: [...state.campaign, campaign] })),
  addSponsor: (sponsor) => set((state) => ({ sponsor: [...state.sponsor, sponsor] })),
  addAgent: (agent) => set((state) => ({ agents: [...state.agents, agent] })),
  // Método para eliminar un usuario por su índice
  removeUserAtIndex: (index) => set((state) => ({ users: state.users.filter((_, i) => i !== index) })),
  // Nuevo método para eliminar una campaña por su ID
  removeCampaignById: (id) => set((state) => ({ campaign: state.campaign.filter((campaign) => campaign.id !== id) })),

  // Nuevo método para eliminar un sponsor por su ID
  removeSponsorById: (id) => set((state) => ({ sponsor: state.sponsor.filter((sponsor) => sponsor.id !== id) })),
  // Método para agregar las campañas a la lista
  setCampaign: (campaign) => set({ campaign }),
  setSponsor: (sponsor) => set({ sponsor }),
  setAgents: (agents) => set({ agents }),
  setBoardUrl: (boardUrl) => set({ boardUrl }),

  setCampaignSelected: (campaignSelected) => set({ campaignSelected }),
  setSelectedData: (selectedData) => set({ selectedData }),
  // Método para limpiar todos los usuarios
  clearUsers: () => set({ users: [] }),
  clearSponsor: () => set({ sponsor: [] }),
  clearCampaign: () => set({ campaign: [] })
}));

export default useDataStore;
