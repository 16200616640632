import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';

// project-imports
import AlertCustomerDelete from './AlertCustomerDelete';
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { ThemeMode } from 'config';

// assets
import { Camera, Trash } from 'iconsax-react';
import useAuth from 'hooks/useAuth';

const avatarImage = require.context('assets/images/users', true);

// constant
/*const getInitialValues = (customer) => {
  // console.log(customer)
  if (customer) {
    console.log("Return edit")
    const editCustomer = {
      name: customer?.name,
      email: customer?.email,
      confirmEmail: customer?.email,
      role: customer?.user_type_id,
      country: customer?.country_id
    };
    return editCustomer;
  } else {
    // console.log("Return new")
    const newCustomer = {
      name: "",
      email: "",
      user_type_id: "",
      country_id: ""
    };
    return newCustomer;
  }
};

const allStatus = ['Complicated', 'Single', 'Relationship'];

const allRoles = ['Super Admin', 'Admin', 'Agente'];*/

// const allCountries = ['Estados Unidos', 'México', 'Argentina', 'Colombia', 'Chile'];

// ==============================|| CUSTOMER - ADD / EDIT ||============================== //

const AddCustomer = ({ customer, onCancel, open, setData, updateData, setCustomerDeleteId }) => {
  const theme = useTheme();
  // if (customer) {
  //   console.log(customer.name)
  // }
  const { getCountries, getUserTypes, addUser, updateUser } = useAuth();
  const isCreating = !customer;

  const [countries, setCountries] = useState([])
  const [selectedImage, setSelectedImage] = useState(undefined);

  // Estados para tipos de usuarios y roles
  const [userTypes, setUserTypes] = useState([]);
  const [roles, setRoles] = useState([]);

  const [apps, setApps] = useState([]);
  const [appsAdd, setAppsAdd] = useState([]);

  // Estado para almacenar la selección actual del tipo de usuario y rol
  const [selectedUserType, setSelectedUserType] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  // const [avatar, setAvatar] = useState(avatarImage(`./avatar-${isCreating && !customer?.avatar ? 1 : customer.avatar}.png`));
  const [initialValuesState, setInitialValuesState] = useState({
    name: customer?.name || '',
    email: customer?.email || '',
    confirmEmail: customer?.email || '',
    country: customer?.country || '',
    status: customer?.status || 'active',
    userType: customer?.user_type || '',
    role: customer?.roles[0] || '',
  })

  // country: Yup.object().required('Debe seleccionar un país'),
  // userType: Yup.object().required('Debe seleccionar un tipo de usuario'),
  // role: Yup.object().required('Debe seleccionar un rol')

  // console.log(customer);

  // useEffect(() => {
  //   const fetchApps = () => {
  //     getAppList()
  //       .then((response) => {
  //         // console.log(response.data)
  //         setApps(response.data.data)
  //       }).catch((error) => {
  //         console.error(error)
  //       }).finally(() => {
  //         //setLoadingData(false)
  //       })
  //   }

  //   fetchApps()
  //   //setSelectedUserType(customer?.user_type_id || '')
  // }, [])

  useEffect(() => {
    if (customer) {
      // console.log("Autocompleting!")
      setInitialValuesState({
        name: customer?.name || '',
        email: customer?.email || '',
        confirmEmail: customer?.email || '',
        country: customer?.country || '',
        status: customer?.status || 'active',
        userType: customer?.user_type || '',
        role: customer?.roles[0] || '',
      })
      setSelectedUserType(customer?.user_type);
      const userType = customer?.user_type;
      formik.setFieldValue('userType', userType);
      // Filtrar y actualizar los roles basados en el userType seleccionado
      const userTypeDetails = userTypes.find((type) => type.id === userType.id);
      setRoles(userTypeDetails?.roles || []);
      formik.setFieldValue('role', customer?.roles[0]);
    } else {
      // console.log("New user!")
      formik.resetForm()
      setInitialValuesState({
        name: '',
        email: '',
        confirmEmail: '',
        country: '',
        status: 'active',
        userType: '',
        role: '',
      })
    }
  }, [customer])

  useEffect(() => {
    const fetchCountries = () => {
      getCountries()
        .then((response) => {
          setCountries(response.data.data)
        }).catch((error) => {
          console.error(error)
        }).finally(() => {

        })
    }

    const fetchRoles = () => {
      getUserTypes()
        .then((response) => {
          setUserTypes(response.data.data)
        }).catch((error) => {
          console.error(error)
        }).finally(() => {

        })
    }

    if (open) {
      fetchCountries()
      fetchRoles()
    }
  }, [open])

  const handleToggle = (app) => {
    setAppsAdd((currentAppsAdd) => {
      if (currentAppsAdd.includes(app.id)) {
        return currentAppsAdd.filter((id) => id !== app.id); // Si ya está en el array, lo quitamos
      } else {
        return [...currentAppsAdd, app.id]; // Si no está en el array, lo añadimos
      }
    });

    // console.log(appsAdd)
  };

  useEffect(() => {
    if (selectedImage) {
      setAvatar(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const CustomerSchema = Yup.object().shape({
    name: Yup.string().max(255).required('Ingrese el nombre del usuario'),
    email: Yup.string().max(255).required('Ingrese un correo electrónico').email('Ingrese un correo electrónico válido'),
    confirmEmail: Yup.string()
      .max(255)
      .required('Confirme el correo electrónico')
      .email('Ingrese un correo electrónico válido')
      .oneOf([Yup.ref('email'), null], 'Los correos electrónicos deben coincidir'),
    country: Yup.object().required('Debe seleccionar un país'),
    userType: Yup.object().required('Debe seleccionar un tipo de usuario'),
    role: Yup.object().required('Debe seleccionar un rol')
  });

  const [openAlert, setOpenAlert] = useState(false);

  const handleAlertClose = () => {
    setOpenAlert(!openAlert);
    setCustomerDeleteId(null)
    formik.resetForm();
    onCancel();
  };

  const handleClose = () => {
    setCustomerDeleteId(null)
    formik.resetForm();
    onCancel();
  };

  const formik = useFormik({
    initialValues: initialValuesState,
    enableReinitialize: true,
    validationSchema: CustomerSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      try {
        if (customer) {
          let formData = new FormData();
          //if (selectedImage) formData.append("logo", selectedImage);
          formData.append("name", values.name)
          formData.append("country_id", values.country.id)
          formData.append("email", values.email)
          formData.append("user_type_id", selectedUserType?.id)
          formData.append("roleName", values.role.name)
          //if (appsAdd.length > 0) formData.append("appsAdd", appsAdd)

          // for (var pair of formData.entries()) {
          //   console.log(`${pair[0]}: ${pair[1]}`);
          // }

          updateUser(customer?.uuid, formData)
            .then((response) => {
              // console.log(response.data)
              dispatch(
                openSnackbar({
                  open: true,
                  message: 'Usuario editado exitosamente',
                  variant: 'alert',
                  alert: {
                    color: 'success'
                  },
                  close: false
                })
              );
              updateData(response.data.data)
              resetForm();
            })
            .catch((error) => {
              console.error(error);
              dispatch(
                openSnackbar({
                  open: true,
                  message: error.message,
                  variant: 'alert',
                  alert: {
                    color: 'error'
                  },
                  close: false
                })
              )
            })
            .finally(() => {
              setSubmitting(false);
              onCancel();
            })
        } else {
          const newCustomer = {
            name: values.name,
            email: values.email,
            user_type_id: selectedUserType?.id,
            country_id: values.country.id,
            roleName: values.role.name,
            appsAdd: appsAdd,
          };
          // console.log(newCustomer)

          addUser(newCustomer)
            .then((response) => {
              // console.log(response.data)
              dispatch(
                openSnackbar({
                  open: true,
                  message: 'Usuario creado exitosamente.',
                  variant: 'alert',
                  alert: {
                    color: 'success'
                  },
                  close: false
                })
              );
              setData(response.data.data);
              resetForm();
            }).catch((error) => {
              console.error(error)
              dispatch(
                openSnackbar({
                  open: true,
                  message: 'Ha ocurrido un error al crear usuario.',
                  variant: 'alert',
                  alert: {
                    color: 'error'
                  },
                  close: false
                })
              );
            }).finally(() => {
              setSubmitting(false);
              onCancel();
            })
        }
        resetForm();
      } catch (error) {
        console.error(error);
      }
    }
  });

  const handleUserTypeChange = (event) => {
    const userType = event.target.value;
    setSelectedUserType(userType);
    formik.setFieldValue('userType', userType);
    // Filtrar y actualizar los roles basados en el userType seleccionado
    const userTypeDetails = userTypes.find((type) => type.id === userType.id);
    setRoles(userTypeDetails?.roles || []);
    formik.setFieldValue('role', '');
  };

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>{customer ? 'Editar Usuario' : 'Nuevo Usuario'}</DialogTitle>
            <Divider />
            <DialogContent >
              <Grid container justifyContent={"center"}>
                <Grid item xs={12} md={7}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="name">Nombre</InputLabel>
                        <TextField
                          fullWidth
                          id="name"
                          // defaultValue={customer && customer.name}
                          placeholder="Ingresa el nombre del usuario"
                          {...getFieldProps('name')}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="customer-email">Correo electrónico</InputLabel>
                        <TextField
                          fullWidth
                          disabled={customer}
                          id="customer-email"
                          placeholder="Ingresa el correo electrónico del usuario"
                          {...getFieldProps('email')}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="confirmEmail">Confirma el correo electrónico</InputLabel>
                        <TextField
                          fullWidth
                          disabled={customer}
                          id="confirmEmail"
                          placeholder="Ingresa el correo electrónico del usuario"
                          {...getFieldProps('confirmEmail')}
                          error={Boolean(touched.confirmEmail && errors.confirmEmail)}
                          helperText={touched.confirmEmail && errors.confirmEmail}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="customer-user-type">Tipo de usuario</InputLabel>
                        <FormControl fullWidth>
                          <Select
                            id="user-type"
                            {...getFieldProps('userType')}
                            onChange={handleUserTypeChange}
                            displayEmpty
                            input={<OutlinedInput id="select-column-hiding" placeholder="Sort by" />}
                            renderValue={(selected) => {
                              if (!selected) {
                                return <Typography variant="subtitle1">Selecciona una opción</Typography>;
                              }

                              return <Typography variant="subtitle2">{selected.name}</Typography>;
                            }}
                          >
                            {userTypes.map((userType) => (
                              <MenuItem key={userType.id} value={userType}>
                                {userType.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {touched.userType && errors.userType && (
                          <FormHelperText error id="standard-weight-helper-text-email-login" sx={{ pl: 1.75 }}>
                            {errors.userType}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="customer-role">Rol del usuario</InputLabel>
                        <FormControl fullWidth>
                          <Select
                            id="role-select"
                            name="role"
                            {...getFieldProps('role')}
                            onChange={formik.handleChange}
                            displayEmpty
                            renderValue={(selected) => {
                              if (selected === '') {
                                return null;
                              }
                              const selectedRole = roles.find((role) => role.id === selected?.id);
                              return selectedRole ? selectedRole.name : null;
                            }}
                          >
                            {roles.map((role) => (
                              <MenuItem key={role.id} value={role}>
                                {role.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {formik.touched.role && formik.errors.role && (
                            <FormHelperText error>{formik.errors.role}</FormHelperText>
                          )}
                        </FormControl>


                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="country">País del usuario</InputLabel>
                        <FormControl fullWidth>
                          <Select
                            id="country"
                            displayEmpty
                            disabled={countries.length <= 0}
                            {...getFieldProps('country')}
                            // onChange={(event) => setFieldValue('orderStatus', event.target.value)}
                            input={<OutlinedInput id="select-column-hiding" placeholder="Sort by" />}
                            renderValue={(selected) => {
                              if (!selected) {
                                return <Typography variant="subtitle1">Selecciona un país</Typography>;
                              }

                              return <Typography variant="subtitle2">{selected?.common_name}</Typography>;
                            }}
                          >
                            {countries.map((country) => (
                              <MenuItem key={country.id} value={country}>
                                <ListItemText primary={country?.common_name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {touched.country && errors.country && (
                          <FormHelperText error id="standard-weight-helper-text-email-login" sx={{ pl: 1.75 }}>
                            {errors.country}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" justifyContent="space-between" alignItems="flex-start" sx={{ marginBottom: 2 }}>
                        <Stack spacing={0.5}>
                          <Typography variant="subtitle1">Aplicaciones Ai-Hub</Typography>
                          <Typography variant="caption" color="textSecondary">
                            Activa las aplicaciones según el tipo de usuario
                          </Typography>
                        </Stack>
                        {/* <FormControlLabel control={<Switch defaultChecked sx={{ mt: 0 }} />} label="" labelPlacement="start" /> */}
                      </Stack>
                      {/* <Divider sx={{ my: 2 }} />
                      <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                        <Stack spacing={0.5}>
                          <Typography variant="subtitle1">Available to hire</Typography>
                          <Typography variant="caption" color="textSecondary">
                            Toggling this will let your teammates know that you are available for acquiring new projects
                          </Typography>
                        </Stack>
                        <FormControlLabel control={<Switch sx={{ mt: 0 }} />} label="" labelPlacement="start" />
                      </Stack> */}
                    </Grid>
                    <Grid container spacing={2}>
                      {apps.map((app) => (
                        <Grid item xs={4} key={app.id}>
                          <FormControlLabel
                            control={
                              <Switch
                                sx={{ mt: 0 }}
                                checked={appsAdd.includes(app.id)}
                                onChange={() => handleToggle(app)}
                              />
                            }
                            label={app.name}
                            labelPlacement="start"
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  {!isCreating && (
                    <Tooltip title="Delete Customer" placement="top">
                      <IconButton onClick={() => setOpenAlert(true)} size="large" color="error">
                        <Trash variant="Bold" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button color="error" onClick={handleClose}>
                      Cancelar
                    </Button>
                    <Button type="submit" variant="contained" className='bg-orange-500 hover:bg-orange-400' disabled={isSubmitting}>
                      {customer ? 'Editar' : 'Agregar'}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
      {!isCreating && <AlertCustomerDelete title={customer.fatherName} open={openAlert} handleClose={handleAlertClose} />}
    </>
  );
};

AddCustomer.propTypes = {
  customer: PropTypes.any,
  onCancel: PropTypes.func
};

export default AddCustomer;


function Car({ brand }) {
  return <h2>I am a {brand}!</h2>;
}