// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project-imports
import authReducer from './auth'
import chat from './chat';
import calendar from './calendar';
import menu from './menu';
import snackbar from './snackbar';
import productReducer from './product';
import cartReducer from './cart';
import kanban from './kanban';
import invoice from './invoice';

// ==============================|| COMBINE REDUCERS ||============================== //

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  whitelist: ['isLoggedIn', 'user'] // Lista las partes del estado que quieres persistir
};

// Se aplica persistReducer solo al reducer auth
const authPersistedReducer = persistReducer(authPersistConfig, authReducer);

const reducers = combineReducers({
  auth: authPersistedReducer,
  chat,
  calendar,
  menu,
  snackbar,
  cart: persistReducer(
    {
      key: 'cart',
      storage,
      keyPrefix: 'able-pro-material-ts-'
    },
    cartReducer
  ),
  product: productReducer,
  kanban,
  invoice
});

export default reducers;

// const reducers = combineReducers({
//   auth,
//   chat,
//   calendar,
//   menu,
//   snackbar,
//   cart: persistReducer(
//     {
//       key: 'cart',
//       storage,
//       keyPrefix: 'able-pro-material-ts-'
//     },
//     cartReducer
//   ),
//   product: productReducer,
//   kanban,
//   invoice
// });
