import PropTypes from 'prop-types';

// material-ui
import { Button, Dialog, DialogContent, Stack, Typography } from '@mui/material';

// project-imports
import Avatar from 'components/@extended/Avatar';
import { PopupTransition } from 'components/@extended/Transitions';

// assets
import { Warning2 } from 'iconsax-react';
import useAuth from 'hooks/useAuth';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';

// ==============================|| CUSTOMER - DELETE ||============================== //

export default function AlertHospitalSuspend({ data, open, handleClose, refresh }) {
    const { updateHospital } = useAuth();
    const [loading, setLoading] = useState(false);

    const handleSuspend = () => {
        var updatedData = data;
        updatedData.status = updatedData.status === 'active' ? 'inactive' : 'active'
        setLoading(true);
        updateHospital(data.uuid, updatedData)
            .then((response) => {
                console.log(response)
                refresh();
                handleClose();
            }).catch((error) => {
                console.error(error)
            }).finally(() => {
                setLoading(false);
            });
    }
    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            keepMounted
            TransitionComponent={PopupTransition}
            maxWidth="xs"
            aria-labelledby="column-delete-title"
            aria-describedby="column-delete-description"
        >
            <DialogContent sx={{ mt: 2, my: 1 }}>
                <Stack alignItems="center" spacing={3.5}>
                    <Avatar color="alert" sx={{ width: 72, height: 72, fontSize: '1.75rem' }}>
                        <Warning2 variant="Bold" />
                    </Avatar>
                    <Stack spacing={2}>
                        <Typography variant="h4" align="center">
                            {`¿Seguro que desea ${data.status === 'active' ? 'suspender' : 'habilitar'} la clínica?`}
                        </Typography>
                        <Typography align="center">
                            <Typography variant="subtitle1" component="span">
                                {' '}
                                &quot;{data?.name || 'No definido'}&quot;{' '}
                            </Typography>
                        </Typography>
                    </Stack>

                    <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                        <Button fullWidth onClick={() => handleClose(false)} color="secondary" variant="outlined">
                            Cancelar
                        </Button>
                        <LoadingButton fullWidth color="info" loading={loading} className={` ${data.status === 'active' ? "bg-yellow-500 hover:bg-yellow-400" : "bg-cyan-500 hover:bg-cyan-400"} text-black font-medium`} variant="contained" onClick={handleSuspend} autoFocus>
                            {`${data.status === 'active' ? 'Suspender' : 'Habilitar'}`}
                        </LoadingButton>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}

AlertHospitalSuspend.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    handleClose: PropTypes.func
};
