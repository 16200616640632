import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const LogoMain = () => {
	const theme = useTheme();
	return (
		/**
		 * if you want to use image instead of svg uncomment following, and comment out <svg> element.
		 *
		 * <img src={theme.palette.mode === ThemeMode.DARK ? logoDark : logo} alt="icon logo" width="100" />
		 *
		 */
		
		<>
			<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
				width="66" height="28" viewBox="0 0 291 147" enableBackground="new 0 0 291 147">

				<path fill="#F47521" opacity="1.000000" stroke="none"
					d="
M154.883224,132.022873 
	C140.438156,138.473450 125.753075,137.541077 110.924004,136.142960 
	C99.716270,135.086288 89.983215,130.640457 82.006783,123.222038 
	C73.535255,115.343185 67.635086,105.381493 65.119400,93.936470 
	C60.763145,74.117783 62.384563,55.178772 74.953430,38.300095 
	C84.713493,25.193338 97.879333,18.489967 114.112885,16.895893 
	C125.567612,15.771081 136.633820,16.702545 147.210907,21.077480 
	C152.545624,23.284048 157.444992,26.543154 162.864227,29.502739 
	C162.864227,25.827883 162.864227,22.265402 162.864227,18.353855 
	C167.925766,18.353855 172.652618,18.353855 177.692520,18.353855 
	C177.692520,31.374872 177.692520,44.427376 177.692520,57.996677 
	C173.478714,57.996677 169.345993,58.204620 165.257278,57.871834 
	C164.006592,57.770039 162.493301,56.424152 161.750977,55.240063 
	C154.527039,43.717278 144.581268,35.977570 131.064117,33.600250 
	C119.422874,31.552860 108.615211,33.783142 99.162422,41.323429 
	C87.672409,50.488766 83.319893,63.056854 83.152832,77.042763 
	C83.017181,88.399788 86.448967,99.181183 94.359474,107.590569 
	C112.367477,126.734253 144.717224,126.171486 159.679794,102.547539 
	C161.095184,100.312813 161.505447,97.441467 162.608002,94.202873 
	C155.448090,94.202873 149.052551,94.202873 142.332886,94.202873 
	C142.332886,89.008560 142.332886,84.287842 142.332886,79.280861 
	C156.430130,79.280861 170.482407,79.280861 184.511566,79.280861 
	C187.069229,99.977402 177.501846,121.105606 154.883224,132.022873 
z"/>
				<path fill="#F47521" opacity="1.000000" stroke="none"
					d="
M203.480835,127.630890 
	C203.117111,130.239334 203.117111,132.732864 203.117111,136.000000 
	C200.454102,136.000000 198.043442,136.000000 195.632782,136.000000 
	C193.316376,136.000000 190.999954,136.000000 188.344131,136.000000 
	C188.344131,121.366554 188.344131,106.979324 188.344131,92.294731 
	C193.071030,92.294731 197.793701,92.294731 203.279785,92.294731 
	C201.463303,99.840080 204.464294,105.748428 209.018692,111.268585 
	C218.714539,123.020424 238.361938,126.948082 247.746536,111.817947 
	C253.030655,103.298729 249.855225,92.420074 240.476776,89.074562 
	C230.236359,85.421562 219.743942,82.480598 209.457870,78.948296 
	C199.979645,75.693420 192.435913,69.993858 189.059692,60.136585 
	C182.418747,40.747612 193.253052,19.117414 215.856796,17.087894 
	C221.139160,16.613609 226.675003,16.510620 231.814102,17.581327 
	C236.969574,18.655449 241.775116,21.409161 246.874512,23.483917 
	C246.961227,21.807680 247.045395,20.180498 247.143784,18.278568 
	C252.282272,18.278568 257.359589,18.278568 262.719940,18.278568 
	C262.719940,30.099945 262.719940,41.820110 262.719940,53.770653 
	C257.586884,53.770653 252.650269,53.770653 247.025192,53.770653 
	C247.745239,47.787117 246.429276,42.524944 241.234756,38.662697 
	C231.865540,31.696470 219.132858,29.930292 209.302368,36.375542 
	C201.755600,41.323490 202.377487,54.523590 210.713348,58.052525 
	C219.484543,61.765774 228.745773,64.469833 237.979477,66.908318 
	C252.731430,70.804077 264.172333,78.211700 267.451843,94.045479 
	C272.272827,117.321327 257.227936,135.895615 235.513168,137.161972 
	C223.480072,137.863708 213.113205,134.964813 203.480835,127.630890 
z"/>
				<path fill="#F47521" opacity="1.000000" stroke="none"
					d="
M62.999344,135.833374 
	C52.680115,135.888733 42.359074,136.085800 32.042248,135.953613 
	C24.107079,135.851944 23.528082,134.806915 25.011820,124.193352 
	C28.447489,123.983765 31.097881,124.168495 33.709702,123.930298 
	C36.073193,123.714737 38.252533,122.960815 35.894630,119.949387 
	C35.894630,91.438164 35.894630,62.926941 35.894630,34.551468 
	C35.217384,34.290783 34.930004,34.095718 34.631870,34.077602 
	C23.936604,33.427902 23.936464,33.430206 24.554522,22.447868 
	C38.426018,22.014244 51.770321,22.028271 65.114624,22.042295 
	C65.067421,25.399279 65.020210,28.756260 64.727875,32.658676 
	C64.264381,33.366909 64.109665,33.576656 64.018600,33.833359 
	C61.346588,33.891121 58.674580,33.948887 55.173836,34.050571 
	C53.230850,35.723602 51.157448,37.345631 51.145363,38.982876 
	C50.945023,66.129150 51.021408,93.277374 50.973648,120.425049 
	C50.969288,122.902817 51.729191,123.911667 54.381710,124.059944 
	C63.006706,124.542107 63.000515,124.652962 62.999962,133.335098 
	C62.999908,134.167847 62.999561,135.000610 62.999344,135.833374 
z"/>
				<path fill="#F47521" opacity="1.000000" stroke="none"
					d="
M63.427998,135.878052 
	C62.999561,135.000610 62.999908,134.167847 62.999962,133.335098 
	C63.000515,124.652962 63.006706,124.542107 54.381710,124.059944 
	C51.729191,123.911667 50.969288,122.902817 50.973648,120.425049 
	C51.021408,93.277374 50.945023,66.129150 51.145363,38.982876 
	C51.157448,37.345631 53.230850,35.723602 54.730324,34.067989 
	C55.115547,62.537144 55.115547,91.032799 55.115547,119.992111 
	C58.857269,119.992111 62.264359,119.992111 66.045410,119.992111 
	C65.270531,125.631973 64.563591,130.777359 63.427998,135.878052 
z"/>
				<path fill="#F47521" opacity="1.000000" stroke="none"
					d="
M65.459854,22.013756 
	C51.770321,22.028271 38.426018,22.014244 24.624187,22.000423 
	C24.166656,20.853374 24.166656,19.706121 24.166656,18.015173 
	C36.525501,18.015173 48.865700,17.901051 61.199280,18.135399 
	C63.099331,18.171501 64.968941,19.809435 66.852631,20.706566 
	C66.503448,21.132782 66.154266,21.559000 65.459854,22.013756 
z"/>
				<path fill="#F47521" opacity="1.000000" stroke="none"
					d="
M35.441917,119.970673 
	C38.252533,122.960815 36.073193,123.714737 33.709702,123.930298 
	C31.097881,124.168495 28.447489,123.983765 25.349270,123.970787 
	C24.971151,122.807343 25.057659,121.656326 25.182751,119.991951 
	C28.455988,119.991951 31.722597,119.991951 35.441917,119.970673 
z"/>
				<path fill="#F47521" opacity="1.000000" stroke="none"
					d="
M64.270828,33.858482 
	C64.109665,33.576656 64.264381,33.366909 64.658043,33.112568 
	C64.729927,33.308552 64.626495,33.596077 64.270828,33.858482 
z"/>
			</svg>
		</>
	);
};

LogoMain.propTypes = {
	reverse: PropTypes.bool
};

export default LogoMain;
