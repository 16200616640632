import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    FormHelperText,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    Switch,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';

// project-imports
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { ThemeMode } from 'config';

// assets
import { Camera, Trash } from 'iconsax-react';
import useAuth from 'hooks/useAuth';
const EditService = ({ customer, onCancel, onUpdate }) => {
    const theme = useTheme();
    const { updatePetService, createPetService } = useAuth();
    const isCreating = !customer;
    // console.log(customer)
    const [avatar, setAvatar] = useState("");
    const [selectedImage, setSelectedImage] = useState(undefined);

    const [initialValuesState, setInitialValuesState] = useState({
        name: customer?.name || '',
        description: customer?.description || '',
        description_time: customer?.description_time || '',
        status: customer?.status || '',
    })

    useEffect(() => {
        if (customer) {
            setInitialValuesState({
                name: customer?.name || '',
                description: customer?.description || '',
                description_time: customer?.description_time || '',
                status: customer?.status || '',
            })
            setAvatar(customer?.img || "");
        } else {
            formik.resetForm()
            setInitialValuesState({
                name: "",
                description: "",
                description_time: "",
                status: "",
            })
            setAvatar("");
        }
    }, [customer])

    const CustomerSchema = Yup.object().shape({
        name: Yup.string().max(255).required('Ingrese el nombre del servicio'),
        description: Yup.string().max(255),
        description_time: Yup.string().max(255).required('Ingrese el tiempo de asistencia del servicio'),
        status: Yup.string().max(10),
    });

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imagePreviewUrl = URL.createObjectURL(file);
            setSelectedImage(file)
            setAvatar(imagePreviewUrl);
            formik.setFieldValue('img', imagePreviewUrl);
        }
    };

    const handleClose = () => {
        formik.resetForm();
        onCancel();
    };

    const formik = useFormik({
        initialValues: initialValuesState,
        enableReinitialize: true,
        validationSchema: CustomerSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            try {
                if (customer) {
                    let formData = new FormData();

                    formData.append("name", values.name);
                    formData.append("description", values.description);
                    formData.append("description_time", values.description_time);
                    if (selectedImage) formData.append("img", selectedImage);

                    // for (var pair of formData.entries()) {
                    //     console.log(`${pair[0]}: ${pair[1]}`);
                    // }

                    updatePetService(customer?.uuid, formData)
                        .then((response) => {
                            console.log(response.data)
                            if (response.data.success) {
                                dispatch(
                                    openSnackbar({
                                        open: true,
                                        message: 'Servicio editado exitosamente',
                                        variant: 'alert',
                                        alert: {
                                            color: 'success'
                                        },
                                        close: true
                                    })
                                );
                                onUpdate()
                                resetForm();
                            } else {
                                dispatch(
                                    openSnackbar({
                                        open: true,
                                        message: response.data.message,
                                        variant: 'alert',
                                        alert: {
                                            color: 'error'
                                        },
                                        close: true
                                    })
                                )
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            dispatch(
                                openSnackbar({
                                    open: true,
                                    message: error.message,
                                    variant: 'alert',
                                    alert: {
                                        color: 'error'
                                    },
                                    close: true
                                })
                            )
                        })
                        .finally(() => {
                            setSubmitting(false);
                            onCancel();
                        })
                } else {
                    let formData = new FormData();

                    formData.append("name", values.name);
                    formData.append("description", values.description);
                    formData.append("description_time", values.description_time);
                    if (selectedImage) formData.append("img", selectedImage);

                    // for (var pair of formData.entries()) {
                    //     console.log(`${pair[0]}: ${pair[1]}`);
                    // }

                    createPetService(formData)
                        .then((response) => {
                            console.log(response.data)
                            if (response.data.success) {
                                dispatch(
                                    openSnackbar({
                                        open: true,
                                        message: 'Servicio creado exitosamente',
                                        variant: 'alert',
                                        alert: {
                                            color: 'success'
                                        },
                                        close: true
                                    })
                                );
                                onUpdate()
                                resetForm();
                            } else {
                                dispatch(
                                    openSnackbar({
                                        open: true,
                                        message: response.data.message,
                                        variant: 'alert',
                                        alert: {
                                            color: 'error'
                                        },
                                        close: true
                                    })
                                )
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            dispatch(
                                openSnackbar({
                                    open: true,
                                    message: error.message,
                                    variant: 'alert',
                                    alert: {
                                        color: 'error'
                                    },
                                    close: true
                                })
                            )
                        })
                        .finally(() => {
                            setSubmitting(false);
                            onCancel();
                        })
                }
                resetForm();
            } catch (error) {
                console.error(error);
            }
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

    return (
        <>
            <FormikProvider value={formik}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <DialogTitle>{isCreating ? "Agregar Servicio" : "Editar Servicio"}</DialogTitle>
                        <Divider />
                        <DialogContent >
                            <Grid container justifyContent={"center"}>
                                <Grid item xs={12} md={3}>
                                    <Stack direction="row" justifyContent="start" sx={{ mt: 3 }}>
                                        <FormLabel
                                            htmlFor="change-avatar"
                                            sx={{
                                                position: 'relative',
                                                borderRadius: '50%',
                                                overflow: 'hidden',
                                                '&:hover .MuiBox-root': { opacity: 1 },
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <Avatar alt="Avatar 1" sx={{ width: 72, height: 72, border: '1px dashed' }} src={avatar} />
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .75)' : 'rgba(0,0,0,.65)',
                                                    width: '100%',
                                                    height: '100%',
                                                    opacity: 0,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <Stack spacing={0.5} alignItems="center">
                                                    <Camera style={{ color: theme.palette.secondary.light, fontSize: '2rem' }} />
                                                    <Typography sx={{ color: theme.palette.secondary.light }}>Cargar</Typography>
                                                </Stack>
                                            </Box>
                                        </FormLabel>
                                        <TextField
                                            type="file"
                                            id="change-avatar"
                                            variant="outlined"
                                            sx={{ display: 'none' }}
                                            onChange={handleImageChange}
                                            inputProps={{
                                                accept: "image/*" // Asegúrate de aceptar solo archivos de imagen
                                            }}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Stack spacing={1.25}>
                                                <InputLabel htmlFor="name"><span style={{ color: theme.palette.primary.main }}>*</span> Nombre</InputLabel>
                                                <TextField
                                                    fullWidth
                                                    id="name"
                                                    placeholder="Ingresa el nombre del usuario"
                                                    {...getFieldProps('name')}
                                                    error={Boolean(touched.name && errors.name)}
                                                    helperText={touched.name && errors.name}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack spacing={1.25}>
                                                <InputLabel htmlFor="description">Descripción</InputLabel>
                                                <TextField
                                                    fullWidth
                                                    id="description"
                                                    placeholder="Ingrese la descripción del servicio..."
                                                    {...getFieldProps('description')}
                                                    error={Boolean(touched.description && errors.description)}
                                                    helperText={touched.description && errors.description}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack spacing={1.25}>
                                                <InputLabel htmlFor="description_time"><span style={{ color: theme.palette.primary.main }}>*</span> Tiempo de Asistencia</InputLabel>
                                                <TextField
                                                    fullWidth
                                                    id="description_time"
                                                    placeholder="Ingrese la descripción del servicio..."
                                                    {...getFieldProps('description_time')}
                                                    error={Boolean(touched.description_time && errors.description_time)}
                                                    helperText={touched.description_time && errors.description_time}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <Divider />
                        <DialogActions sx={{ p: 2.5 }}>
                            <Grid container justifyContent="end" alignItems="center">
                                <Grid item>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Button color="error" onClick={handleClose}>
                                            Cancelar
                                        </Button>
                                        <Button type="submit" variant="contained" className={`${isSubmitting ? "bg-slate-500 hover:bg-slate-500" : "bg-orange-500 hover:bg-orange-400"}`} disabled={isSubmitting}>
                                            {isCreating ? "Agregar" : "Editar"}
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Form>
                </LocalizationProvider>
            </FormikProvider>
        </>
    );
};

EditService.propTypes = {
    customer: PropTypes.any,
    onCancel: PropTypes.func
};

export default EditService;