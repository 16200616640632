import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// project-imports
import Loadable from 'components/Loadable';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import UserListPage from 'pages/main/users/UserListPage';

// render - landing page
const PagesLanding = Loadable(lazy(() => import('pages/landing')));

// ==============================|| ROUTES RENDER ||============================== //

export default function ThemeRoutes() {

  return useRoutes([
    {
      path: '/',
      element: <MainLayout/>,
      children: [
        {
          path: '',
          element: (
            <AuthGuard>
              <UserListPage />
            </AuthGuard>
          )
        }
      ]
    },
    {
      path: '/home',
      element: <MainLayout/>,
      children: [
        {
          path: '',
          element: (
            <AuthGuard>
              <UserListPage />
            </AuthGuard>
          )
        }
      ]
    },
    LoginRoutes,
    MainRoutes
  ]);
}
