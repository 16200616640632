import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import { Chance } from 'chance';
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';
import axios from 'utils/axios';
import useAuthStore from 'store/useAuthStore';
import Loader from 'components/Loader';

const chance = new Chance();

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const { user, isLoggedIn } = useAuthStore();

  useEffect(() => {
    const init = async () => {
      try {

        const serviceToken = localStorage.getItem('serviceToken');
        if (isLoggedIn) {
          setSession(serviceToken);

          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user
            }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);

  const login = async (email, password, navigate) => {
    const response = await axios.post('/api/admin/login', { email, password });
    const { token, user } = response.data;
    useAuthStore.getState().login(token, user);
    setSession(token);
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user
      }
    });
    navigate("/users/list");
  };

  const logout = () => {
    setSession(null);
    useAuthStore.getState().logout();
    dispatch({ type: LOGOUT });
  };

  const getUserList = async () => {
    //axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    return await axios.get(`/api/user/list`);
  };

  const deleteUser = async (userId) => {
    return await axios.delete(`/api/user/delete/${userId}`);
  }

  const addUser = async (data) => {
    return await axios.post(`/api/user/create`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
  }

  const getPetList = async (user) => {
    return await axios.get(`/api/pet/user/list/${user}`);
  };

  const getHospitalList = async () => {
    return await axios.get(`/api/clinic/admin/list`);
  };

  const getServiceList = async () => {
    return await axios.get(`/api/services/admin/list`);
  };

  const createHospital = async (data) => {
    return await axios.post(`/api/clinic/create`, data);
  };

  const updateHospital = async (hospitalId, data) => {
    return await axios.post(`/api/clinic/update/${hospitalId}`, data);
  };

  const confirm = async (uuid, email, password, firstName, lastName) => {
    const response = await axios.post('/api/user/confirm', {
      "uuid": uuid,
      "password": password
    });
    // console.log(response)
  };

  const verificate = async (uuid, code) => {
    //axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    const response = await axios.post('/api/register/validate/email', {
      "uuid": uuid,
      "otp_code": code
    });
    console.log(response)
    return response
  };

  const resetPassword = async (data) => {
    return await axios.post('/api/reset/password', data);
  };

  const newPassword = async (data) => {
    return await axios.post('/api/new/password', data);
  };

  const updateProfile = () => { };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  //---- ENDPOINTS VETERINARY ----- //
  const getPetClientList = async () => {
    return await axios.get('/api/user/client/list');
  };

  const addClients = async (data) => {
    return await axios.post(`/api/user/update/clients`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
  }

  const updatePetClient = async (user, data) => {
    return await axios.post(`/api/user/update/${user}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
  }

  const createPetService = async (data) => {
    return await axios.post(`/api/services/create`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
  }

  const updatePetService = async (service, data) => {
    return await axios.post(`/api/services/update/${service}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
  }

  const value = {
    ...state,
    login,
    logout,
    confirm,
    verificate,
    resetPassword,
    newPassword,
    updateProfile,
    getUserList,
    addUser,
    deleteUser,
    getPetList,
    getHospitalList,
    getServiceList,
    createHospital,
    updateHospital,
    getPetClientList,
    addClients,
    updatePetClient,
    createPetService,
    updatePetService
  };

  return <JWTContext.Provider value={value}>{children}</JWTContext.Provider>;

};

JWTProvider.propTypes = {
  children: PropTypes.node
};

export default JWTContext;