import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, Grid, Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import React from 'react';
import { Link } from 'react-router-dom';
import useBreadcrumbStore from 'store/useBreadcrumbStore';
import { ArrowRight2, Buildings2, Home2, Home3 } from 'iconsax-react';
import { ThemeMode } from 'config';

const CustomBreadcrumbs = () => {
    const theme = useTheme();
    const breadcrumbs = useBreadcrumbStore((state) => state.breadcrumbs);

    return (
        <MuiBreadcrumbs
            aria-label="breadcrumb"
            separator={<ArrowRight2 size={"12"} />}
            sx={{
                '& .MuiBreadcrumbs-separator': {
                    margin: theme.spacing(0, 0.5),
                },
                '& a': {
                    textDecoration: 'none',
                    color: theme.palette.mode === ThemeMode.DARK ? theme.palette.grey[400] : theme.palette.text.secondary,
                    '&:hover': {
                        color: theme.palette.primary.main,
                    },
                },
                mb: "30px"
            }}
        >
            {breadcrumbs.map((crumb, index) => (
                <Link key={index} to={crumb?.path || "/"}>
                    <div className='flex items-center'>
                        <Typography className={`hover:text-orange-500 duration-150 ${index + 1 === breadcrumbs.length && "text-orange-500"}`}>{crumb?.label || ""}</Typography>
                    </div>
                </Link>
            ))}
        </MuiBreadcrumbs>
    );
};

export default CustomBreadcrumbs;
