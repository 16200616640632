import axios from 'axios';
import { dispatch } from 'store';
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import useAuthStore from 'store/useAuthStore';

const axiosServices = axios.create({ baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3010/' });

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      logout()
      window.location.pathname = '/login';
    }
    if (error.response.message && !window.location.href.includes('/login')) {
      window.location.pathname = '/login';
    }
    return Promise.reject((error.response && error.response.data) || 'Wrong Services');
  }
);

const logout = () => {
  setSession(null);
  useAuthStore.getState().logout();
  dispatch({ type: LOGOUT });
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export default axiosServices;
